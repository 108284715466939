import ky from "ky";
import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import type { Wishlist } from "types/wishlist";

import type {
  WishlistResponse,
  WishlistBlankResponse,
  AddToWishlistRequest,
  RemoveFromWishlistRequest,
} from "~/api/wishlist";

import { useSite } from "./useSite";

const keyWithProducts = ["wishlist", { includeProducts: true }];
const keyWithoutProducts = ["wishlist", { includeProducts: false }];

export function useWishlist({
  includeProducts = false,
}: { includeProducts?: boolean } = {}) {
  const site = useSite();

  return useQuery({
    queryKey: ["wishlist", { includeProducts }],
    queryFn: async () => {
      const searchParams = new URLSearchParams();

      searchParams.set(
        "includeProducts",
        true === includeProducts ? "true" : "false",
      );

      const res = await ky
        .get(`/api/wishlist?${searchParams.toString()}`, {
          headers: { "X-Site-Handle": site.handle },
        })
        .json<WishlistResponse>();

      return res.wishlist;
    },
  });
}

export function useWishlistCount() {
  const { data, isPending } = useWishlist();
  if (isPending) return undefined;
  return data?.productHandles.length ?? 0;
}

export function useProductInWishlist({
  productHandle,
}: {
  productHandle: string;
}) {
  const { data } = useWishlist();
  return data?.productHandles.includes(productHandle) ?? false;
}

export function useWishlistProducts() {
  const { data, isPending } = useWishlist({ includeProducts: true });

  return useMemo(
    () => ({
      isPending,
      products: data?.products ?? [],
    }),
    [isPending, data],
  );
}

export function useWishlistAdd() {
  const site = useSite();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["wishlist", "add"],
    mutationFn: async (variables: Omit<AddToWishlistRequest, "action">) =>
      ky
        .post("/api/wishlist", {
          json: {
            ...variables,
            action: "ADD_TO_WISHLIST",
          } satisfies AddToWishlistRequest,
          headers: { "X-Site-Handle": site.handle },
        })
        .json<WishlistResponse>(),
    onMutate: async (variables) => {
      // Prevent the result of other queries overwriting our optimistic update.
      await queryClient.cancelQueries({ queryKey: keyWithProducts });
      await queryClient.cancelQueries({ queryKey: keyWithoutProducts });

      queryClient.setQueryData<Wishlist>(keyWithoutProducts, (wishlist) => {
        // We don't have a wishlist yet, not possible to optimistically update.
        if (!wishlist) return wishlist;

        // Optimistically update the product handles to include our newly added product.
        return {
          ...wishlist,
          productHandles: [...wishlist.productHandles, variables.productHandle],
        };
      });
    },
    onSuccess: (data) => {
      // Set the "without products" query to the response from the mutation.
      queryClient.setQueryData(keyWithoutProducts, data.wishlist);

      // Invalidate the "with products" query, so that it refetches when needed.
      queryClient.invalidateQueries({ queryKey: keyWithProducts });
    },
  });

  return useMemo(
    () => ({
      isPending,
      addToWishlist: mutateAsync,
    }),
    [mutateAsync, isPending],
  );
}

export function useWishlistRemove() {
  const site = useSite();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["wishlist", "remove"],
    mutationFn: async (variables: Omit<RemoveFromWishlistRequest, "action">) =>
      ky
        .post("/api/wishlist", {
          json: {
            ...variables,
            action: "REMOVE_FROM_WISHLIST",
          } satisfies RemoveFromWishlistRequest,
          headers: { "X-Site-Handle": site.handle },
        })
        .json<WishlistBlankResponse>(),
    onMutate: async (variables) => {
      // Prevent the result of other queries overwriting our optimistic update.
      await queryClient.cancelQueries({ queryKey: keyWithProducts });
      await queryClient.cancelQueries({ queryKey: keyWithoutProducts });

      queryClient.setQueryData<Wishlist>(keyWithProducts, (wishlist) => {
        // We don't have a wishlist yet, not possible to optimistically update.
        if (!wishlist) return wishlist;

        // Optimistically update the products and handles to remove the given product.
        return {
          ...wishlist,
          products: wishlist.products.filter(
            (product) => product.handle !== variables.productHandle,
          ),
          productHandles: wishlist.productHandles.filter(
            (productHandle) => productHandle !== variables.productHandle,
          ),
        };
      });

      queryClient.setQueryData<Wishlist>(keyWithoutProducts, (wishlist) => {
        // We don't have a wishlist yet, not possible to optimistically update.
        if (!wishlist) return wishlist;

        // Optimistically update the product handles to remove the given product.
        return {
          ...wishlist,
          productHandles: wishlist.productHandles.filter(
            (productHandle) => productHandle !== variables.productHandle,
          ),
        };
      });
    },
  });

  return useMemo(
    () => ({
      isPending,
      removeFromWishlist: mutateAsync,
    }),
    [mutateAsync, isPending],
  );
}

export function useWishlistToggle({
  variantId,
  productHandle,
}: {
  productHandle: string;
  variantId?: string | null;
}) {
  const isInWishlist = useProductInWishlist({ productHandle });

  const { addToWishlist, isPending: isPending1 } = useWishlistAdd();
  const { removeFromWishlist, isPending: isPending2 } = useWishlistRemove();

  const isPending = isPending1 || isPending2;

  const toggleWishlist = useCallback(() => {
    if (isInWishlist) removeFromWishlist({ productHandle });
    else addToWishlist({ productHandle, variantId });
  }, [
    isInWishlist,
    addToWishlist,
    removeFromWishlist,
    variantId,
    productHandle,
  ]);

  return useMemo(
    () => ({
      isPending,
      isInWishlist,
      toggleWishlist,
    }),
    [toggleWishlist, isPending, isInWishlist],
  );
}
